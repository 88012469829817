@import url('https://fonts.googleapis.com/css?family=Roboto:wght@500&display=swap');

@import "./colors";


@font-face {
    font-family: 'YekanHair';
    src: url('Fonts/Fa-En/Yekan Bakh FaNum 01 Hairline.eot'); /* IE9 Compat Modes */
    src: url('Fonts/Fa-En/Yekan Bakh FaNum 01 Hairline.eot?#iefix') format('embedded-opentype');
    src:url('Fonts/Fa-En/Yekan Bakh FaNum 01 Hairline.woff') format('woff');
    src:url('Fonts/Fa-En/Yekan Bakh FaNum 01 Hairline.ttf')  format('truetype');
}

@font-face {
    font-family: 'YekanThin';
    src: url('Fonts/Fa-En/Yekan Bakh FaNum 02 Thin.eot'); /* IE9 Compat Modes */
    src: url('Fonts/Fa-En/Yekan Bakh FaNum 02 Thin.eot?#iefix') format('embedded-opentype');
    src:url('Fonts/Fa-En/Yekan Bakh FaNum 02 Thin.woff') format('woff');
    src:url('Fonts/Fa-En/Yekan Bakh FaNum 02 Thin.ttf')  format('truetype');
}

@font-face {
    font-family: 'YekanLight';
    src: url('Fonts/Fa-En/Yekan Bakh FaNum 03 Light.eot'); /* IE9 Compat Modes */
    src: url('Fonts/Fa-En/Yekan Bakh FaNum 03 Light.eot?#iefix') format('embedded-opentype');
    src:url('Fonts/Fa-En/Yekan Bakh FaNum 03 Light.woff') format('woff');
    src:url('Fonts/Fa-En/Yekan Bakh FaNum 03 Light.ttf')  format('truetype');
}

@font-face {
    font-family: 'YekanRegular';
    src: url('Fonts/Fa-En/Yekan Bakh FaNum 04 Regular.eot'); /* IE9 Compat Modes */
    src: url('Fonts/Fa-En/Yekan Bakh FaNum 04 Regular.eot?#iefix') format('embedded-opentype');
    src:url('Fonts/Fa-En/Yekan Bakh FaNum 04 Regular.woff') format('woff');
    src:url('Fonts/Fa-En/Yekan Bakh FaNum 04 Regular.ttf')  format('truetype');
}

@font-face {
    font-family: 'YekanMedium';
    src: url('Fonts/Fa-En/Yekan Bakh FaNum 05 Medium.eot'); /* IE9 Compat Modes */
    src: url('Fonts/Fa-En/Yekan Bakh FaNum 05 Medium.eot?#iefix') format('embedded-opentype');
    src:url('Fonts/Fa-En/Yekan Bakh FaNum 05 Medium.woff') format('woff');
    src:url('Fonts/Fa-En/Yekan Bakh FaNum 05 Medium.ttf')  format('truetype');
}

@font-face {
    font-family: 'YekanBold';
    src: url('Fonts/Fa-En/Yekan Bakh FaNum 06 Bold.eot'); /* IE9 Compat Modes */
    src: url('Fonts/Fa-En/Yekan Bakh FaNum 06 Bold.eot?#iefix') format('embedded-opentype');
    src:url('Fonts/Fa-En/Yekan Bakh FaNum 06 Bold.woff') format('woff');
    src:url('Fonts/Fa-En/Yekan Bakh FaNum 06 Bold.ttf')  format('truetype');
}

@font-face {
    font-family: 'YekanHeavy';
    src: url('Fonts/Fa-En/Yekan Bakh FaNum 07 Heavy.eot'); /* IE9 Compat Modes */
    src: url('Fonts/Fa-En/Yekan Bakh FaNum 07 Heavy.eot?#iefix') format('embedded-opentype');
    src:url('Fonts/Fa-En/Yekan Bakh FaNum 07 Heavy.woff') format('woff');
    src:url('Fonts/Fa-En/Yekan Bakh FaNum 07 Heavy.ttf')  format('truetype');
}

@font-face {
    font-family: 'YekanFat';
    src: url('Fonts/Fa-En/Yekan Bakh FaNum 08 Fat.eot'); /* IE9 Compat Modes */
    src: url('Fonts/Fa-En/Yekan Bakh FaNum 08 Fat.eot?#iefix') format('embedded-opentype');
    src:url('Fonts/Fa-En/Yekan Bakh FaNum 08 Fat.woff') format('woff');
    src:url('Fonts/Fa-En/Yekan Bakh FaNum 08 Fat.ttf')  format('truetype');
}




//utils
.star-pan{
    background-color: white;
    border-radius: 8px;
    padding: 15px;
    margin:20px
}

.toast-class{
    font-family: YekanRegular;
    font-size:16px
}

.ant-popover-inner-content,.ant-message{
    font-family: YekanRegular;
}

.ant-select{
    font-family: YekanRegular;
}
.click{
    cursor: pointer;
}
.click:active{
    opacity: 0.3;
}

.sum-section{
    display: flex;
    align-items: center;
    justify-content: center;
    //background-color: #5d76cd;
    .sum-item{
        margin: 10px 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        span{
            color: #6c6464;
        }
    }
}
.ant-message-notice-content{
    border-radius: 16px !important;
}

.ant-select-selector{
    width: 100%;
    outline: none;
    border: none!important;
}

.token-input{
    letter-spacing: 10px;
}