body{
  background-color: #E3E2F6 !important;
}

.new-login-container{
  .new-login-header{
    display: flex;
    justify-content: space-between;
    margin: 10px 50px;
    img{
      width:80px
    }
    .new-login-menu{
      display: flex;
      align-items: center;
      span{
        margin: 10px;
      }
    }
  }
  .new-login-body{
    //background-color: orange;
    display: flex;
    margin-top: 50px;
    .new-login-frame-offset{
      flex:2
    }
    .new-login-frame{
      flex: 8;
      display: flex;
      //background-color: forestgreen;

      .new-login-spin-form{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .new-login-body-form{
        //padding:50px ;
        border-radius: 0 15px 15px 0;
        background-color: #ffffff;
        flex: 4;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 400px;
        height: 600px;
        .main-logo{

          img{
            width:150px
          }

        }

        .mode{
          font-size: 20px;
          font-weight: bold;
          margin: 30px 0 15px 0;
          font-family: YekanRegular;
        }

        .login-name{
          font-family: YekanRegular;
          font-size: 13px;
        }

        .new-login-input{
          margin: 20px 0;
          .input-sec{
            position: relative;
            input{
              width:100%;
              border: none;
              border-bottom: 1px solid gray;
              font-size: 18px;
              text-align: center;
              outline: none;
            }
            img{
              position: absolute;
              bottom: 5px;
              width:20px
            }
          }
          .message-sec{
            text-align: center;
            color: #a3a1a1;
            font-family: YekanRegular !important;
          }
          .message-alert{
            color: #F59782;
          }
        }

        .submit-btn{
          background-color: #605BFF;
          border-radius: 8px;
          padding: 8px 30px;
          width: 200px;
          text-align: center;
          color:white;
          cursor: pointer;
          font-family: YekanRegular;
        }
        .submit-btn:active{
          opacity: 0.3;
        }

      }
      .new-login-body-billboard{
        width: 400px;
        border-radius: 15px 0 0 15px;
        background-color: #605BFF;
        flex: 6;
        display: flex;
        justify-content: center;
        position: relative;
        height: 600px;
        .billboard-wrapper{
          border-radius: 10px 0px 0px 10px;
          width:100%;
          height:100%;
          background-color: #605bff;
          opacity: 0.7;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          .billboard-title{
            color:white;
            font-family: 'Roboto', sans-serif;
            font-size: 70px;
            letter-spacing: 15px;
          }
          .billboard-text{
            color:white;
            font-size: 40px;
            font-family: 'Roboto', sans-serif;
          }
        }

        img{
          position: absolute;
          bottom: -2px;
        }
      }

    }

  }
}


@media only screen and (max-width: 800px) {
  .new-login-body-form{
    border-radius: 15px !important;
    width: 100% !important;
    margin: 0 20px !important;
  }
  .new-login-body-billboard{
    display: none !important;
  }
  .new-login-frame-offset{
    display: none !important;
  }
}